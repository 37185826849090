.PhoneInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.PhoneInput--focus {
  border-color: #333;
}

.PhoneInput--invalid {
  border-color: #ff0000;
}

.PhoneInputCountry {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  padding: 0 10px;
  border-right: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.PhoneInputCountry--focus {
  border-color: #333;
}

.PhoneInputCountry--invalid {
  border-color: #ff0000;
}

.PhoneInputCountrySelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border: none;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.PhoneInputCountrySelect--focus {
  border-color: #333;
}

.PhoneInputCountrySelect--invalid {
  border-color: #ff0000;
}

.PhoneInputCountrySelectArrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  border: none;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.PhoneInputCountrySelectArrow--focus {
  border-color: #333;
}

.PhoneInputCountrySelectArrow--invalid {
  border-color: #ff0000;
}

.PhoneInput .PhoneInputInput {
  border: none;
  outline: none !important;
}


.PhoneInput .PhoneInputInput:focus {
  border: none;
  outline: none !important;
  --tw-ring-color: transparent;
}
