@tailwind base;
@tailwind components;
@tailwind utilities;
@import '/node_modules/react-tailwindcss-datepicker/dist/index.css';

* {
  scroll-behavior: smooth;
}

#__next {
  min-height: 100%;
}

/* Disable dark mode */
/* .dark\:bg-white {
  background-color: #fff !important;
}

.dark\:bg-slate-800 {
  background-color: #fff !important;
}

.dark\:text-black {
  color: #000 !important;
} */

.calender {
  background-color: #fff !important;
  color: black !important;
}

.calender button {
  color: black;
}

.calender button.text-white {
  color: white;
}

.calender .border-gray-300 {
  border-color: #eee !important;
}

.calender .dark\:bg-slate-800  {
  background-color: #fff !important;
}

/* .calender .dark\:text-white\/80  {
  color: black;
} */

.calender .dark\:border-slate-600 {
  border-color: #eee;
}